


.heading {
  font-family: "Roboto", sans-serif;
  font-size: 26px;
  line-height: 1;
  color: #454cad;
  margin-bottom: 0;
}
.lead {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  color: #5f6982;
}

.uploader {
  display: block;
  clear: both;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;
}

.uploader label {
  float: left;
  clear: both;
  width: 100%;
  padding: 2rem 1.5rem;
  text-align: center;
  background: #fff;
  border-radius: 7px;
  border: 3px solid #eee;
  transition: all .2s ease;
  user-select: none;
}

.uploader label:hover {
  border-color: #454cad;
}

.uploader label.hover {
  border: 3px solid #454cad;
  box-shadow: inset 0 0 0 6px #eee;
}

.uploader label.hover #start i.fa {
  transform: scale(0.8);
  opacity: 0.3;
}

#start {
  float: left;
  clear: both;
  width: 100%;
}

#start.hidden {
  display: none;
}

#start i.fa {
  font-size: 50px;
  margin-bottom: 1rem;
  transition: all .2s ease-in-out;
}

#response {
  float: left;
  clear: both;
  width: 100%;
}

#response.hidden {
  display: none;
}

#messages {
  margin-bottom: .5rem;
}

#file-image {
  display: inline;
  margin: 0 auto .5rem auto;
  width: auto;
  height: auto;
  max-width: 180px;
}

#file-image.hidden {
  display: none;
}

#notimage {
  display: block;
  float: left;
  clear: both;
  width: 100%;
}

#notimage.hidden {
  display: none;
}

progress,
.progress {
  display: inline;
  clear: both;
  margin: 0 auto;
  width: 100%;
  max-width: 180px;
  height: 8px;
  border: 0;
  border-radius: 4px;
  /* background-color: #eee; */
  overflow: hidden;
}

.progress[value]::-webkit-progress-bar {
  border-radius: 4px;
  /* background-color: #eee; */
}

.progress[value]::-webkit-progress-value {
  background: linear-gradient(to right, darken(#454cad, 8%) 0%, #454cad 50%);
  border-radius: 4px;
}

.progress[value]::-moz-progress-bar {
  background: linear-gradient(to right, darken(#454cad, 8%) 0%, #454cad 50%);
  border-radius: 4px;
}

input[type="file"] {
  display: none;
}

.uploader div {
  margin: 0 0 .5rem 0;
  color: #5f6982;
}

.btn {
  display: inline-block;
  margin: .5rem .5rem 1rem .5rem;
  clear: both;
  font-family: inherit;
  font-weight: 700;
  font-size: 14px;
  text-decoration: none;
  text-transform: initial;
  border: none;
  border-radius: .2rem;
  outline: none;
  padding: 0 1rem;
  height: 36px;
  line-height: 36px;
  color: #fff;
  transition: all 0.2s ease-in-out;
  box-sizing: border-box;
  background: #454cad;
  border-color: #454cad;
  cursor: pointer;
}













.container-form {
  /* position: relative; */
  width: 100%;
  border-radius: 6px;
  /* padding: 30px; */
  /* margin: 0 15px; */
  margin-top: 150px;
  /* margin-left: 15px;
  margin-right: 30px; */
  background-color: #fff;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.container-form header {
  font-size: 20px;
  font-weight: 600;
  color: #333;
  position: relative;
}

.container-form header::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: -2px;
  height: 3px;
  width: 27px;
  border-radius: 8px;
}

.container-form form {
  position: relative;
  margin-top: 16px;
  min-height: 600px;
  overflow: hidden;
}

.container-form form .form {
  /* position: absolute; */
  transition: 0.3s ease;
}

.container-form form .form.second {
  /* opacity: 0; */
  pointer-events: none;
  transform: translateX(0%);
}

form.secActive .form.second {
  opacity: 1;
  pointer-events: auto;
  /* transform: translateX(0); */
}

form.secActive .form.first {
  opacity: 0;
  pointer-events: none;
  transform: translateX(-100%);
}

.container-form form .title {
  display: block;
  margin: 6px 0;
  font-size: 20px;
  font-weight: 500;
  color: #333;
}

.container-form form .fields {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.fields .input-field {
  display: flex;
  width: calc(100% / 3 - 15px);
  flex-direction: column;
  margin: 4px 0;
}

.input-field label {
  font-size: 12px;
  font-weight: 500;
  color: #2e2e2e;
}

.input-field input,
.input-field select{
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 0 15px;
  height: 42px;
  margin: 8px 0;
}

.input-field textarea {
  outline: none;
  font-size: 14px;
  font-weight: 400;
  color: #333;
  border-radius: 5px;
  border: 1px solid #aaa;
  padding: 0 15px;
  /* height: 42px; */
  padding-top: 15px;
  margin: 8px 0;
}

.input-field input:focus,
.input-field select:focus {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.13);
}

.input-field select,
.input-field input[type="date"] {
  color: #707070;
}

.input-field input[type="date"]:valid {
  color: #333;
}

.container-form form button,
.backBtn {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  height: 45px;
  /* width: 10%; */
  padding:10px;
  border: none;
  color: #fff;
  border-radius: 5px;
  margin: 25px 0;
  margin-right: 20px;
  background-color: #4070f4;
  transition: all 0.3s linear;
  cursor: pointer;
}

.container-form form .btnText {
  font-size: 20px;
  font-weight: 400;
}

form button:hover {
  background-color: #265df2;
}

form button i,
.backBtn i {
  margin: 0 6px;
}

.backBtn i {
  transform: rotate(180deg);
}

form .buttons {
  /* display: flex; */
  align-items: center;
}

.buttons button,
.backBtn {
  margin-right: 14px;
}

/* Responsive Styles */
@media (max-width: 750px) {
  .container-form form {
      overflow-y: scroll;
  }

  .container-form form::-webkit-scrollbar {
      display: none;
  }

  .fields .input-field {
      width: calc(100% / 2 - 15px);
  }
}

@media (max-width: 550px) {
  .fields .input-field {
      width: 100%;
  }
}
.image-upload {
  width: calc(50% - 15px);
  border: 2px solid grey;
  text-align: center;
  cursor: pointer;
}

.image-preview {
  width: calc(50% - 15px);
  height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #8f8484;
  border-radius: 5px;
  overflow: hidden;

  background-color: #f9f9f9;
}

.preview-img {
  max-width: 100%;
  max-height: 100%;
}
