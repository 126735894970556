@import url("https://fonts.googleapis.com/css?family=Montserrat:400,700");

* {
  box-sizing: border-box;
}

.cards-container {
  display: flex;
  flex-wrap: wrap;
  --length: 5;
  padding: 0 1rem;
}

.card {
  --stop: calc(100% / var(--length) * var(--i));
  --l: 62%;
  --l2: 88%;
  --h: calc((var(--i) - 1) * (180 / var(--length)));
  --c1: hsl(var(--h), 71%, var(--l));
  --c2: hsl(var(--h), 71%, var(--l2));
  
  position: relative;
  counter-increment: list;
  max-width: 70rem;
  margin: 2rem auto;
  padding: 2rem 1rem 1rem;
  box-shadow: 0.1rem 0.1rem 1.5rem rgba(0, 0, 0, 0.3);
  border-radius: 0.25rem;
  overflow: hidden;
  background-color: white;
}

.card::before {
  content: '';
  display: block;
  width: 100%;
  height: 1rem;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, var(--c1) var(--stop), var(--c2) var(--stop));
}

.card-title {
  display: flex;
  align-items: baseline;
  margin: 0 0 1rem;
  color: rgb(70 70 70);
}

.card-title::before {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  margin-right: 1rem;
  width: 3rem;
  height: 3rem;
  content: counter(list);
  padding: 1rem;
  border-radius: 50%;
  background-color: var(--c1);
  color: white;
}

.card-description {
  margin: 0;
  line-height: 1.6;
}

@media (min-width: 40em) {
  .card {
    margin: 3rem auto;
    padding: 3rem 2rem 2rem;
  }

  .card-title {
    font-size: 2.25rem;
    margin: 0 0 2rem;
  }

  .card-title::before {
    margin-right: 1.5rem;
  }
}
