.core-value {
    margin-bottom: 40px;
  }
  
  .core-value-content {
    background-color: #fff;
    padding: 30px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    height: 100%;
  }
  
  .core-value:hover .core-value-content {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  }
  
  .core-value h2 {
    color: #333;
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .core-value p {
    color: #666;
    line-height: 1.6;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 10px;
    }
    
    .core-value-content {
      padding: 20px;
    }
    
    .core-value h2 {
      font-size: 20px;
    }
  }