
  
  .heading1 {
    letter-spacing: -1px;
  }
  .anchor {
    color: #003478;
    text-decoration: unset;
  }
  .login-root {
    background: #fff;
    display: flex;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
  }
  .loginbackground {
    min-height: 692px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 0;
    overflow: hidden;
  }
  .flex-flex {
    display: flex;
  }
  .align-center {
    align-items: center;
  }
  .center-center {
    align-items: center;
    justify-content: center;
  }
  .box-root {
    box-sizing: border-box;
  }
  .flex-direction--column {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .loginbackground-gridContainer {
    display: -ms-grid;
    display: grid;
    -ms-grid-columns: [start] 1fr [left-gutter] (86.6px) [column16] [left-gutter] 1fr [end];
    grid-template-columns: [start] 1fr [left-gutter] repeat(16, 86.6px) [left-gutter] 1fr [end];
    -ms-grid-rows: [top] 1fr [top-gutter] (64px) [eight] [bottom-gutter] 1fr [bottom];
    grid-template-rows: [top] 1fr [top-gutter] repeat(8, 64px) [bottom-gutter] 1fr [bottom];
    justify-content: center;
    margin: 0 -2%;
    transform: rotate(-12deg) skew(-12deg);
  }
  .box-divider--light-all-2 {
    box-shadow: inset 0 0 0 2px #e3e8ee;
  }
  .box-background--blue {
    background-color: #003478;
    color: white;
  }
  .box-background--white {
    background-color: #ffffff;
  }
  .box-background--blue800 {
    background-color: #212d63;
    color: white;

  }
  .box-background--gray100 {
    background-color: #e3e8ee;
  }
  .box-background--cyan200 {
    background-color: #7fd3ed;
  }
  .padding-top--64 {
    padding-top: 64px;
  }
  .padding-top--24 {
    padding-top: 24px;
  }
  .padding-top--48 {
    padding-top: 48px;
  }
  .padding-bottom--24 {
    padding-bottom: 24px;
  }
  .padding-horizontal--48 {
    padding: 48px;
  }
  .padding-bottom--15 {
    padding-bottom: 15px;
  }
  
  .flex-justifyContent--center {
    -ms-flex-pack: center;
    justify-content: center;
  }
  
  .formbg {
    margin: 0px auto;
    width: 100%;
    max-width: 550px;
    background: white;
    border-radius: 4px;
    box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
      rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
  }
  .span1 {
    display: block;
    font-size: 20px;
    line-height: 28px;
    /* color: #1a1f36; */
  }
  .label1 {
    margin-bottom: 0px;
  }
  .reset-pass .anchor,
  .label1 {
    font-size: 14px;
    font-weight: 600;
    display: block;
  }
  .reset-pass > .anchor {
    text-align: right;
    margin-bottom: 10px;
  }
  .grid--50-50 {
    display: grid;
    grid-template-columns: 50% 50%;
    align-items: center;
  }
  
  .field input {
    font-size: 16px;
    line-height: 28px;
    padding: 6px 12px;
    width: 100%;
    min-height: 30px;
    border: unset;
    border-radius: 4px;
    outline-color: rgb(84 105 212 / 0.5);
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(60, 66, 87, 0.16) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
  
  input[type="submit"] {
    background-color: rgb(84, 105, 212);
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0.12) 0px 1px 1px 0px, rgb(84, 105, 212) 0px 0px 0px 1px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.08) 0px 2px 5px 0px;
    color: #fff;
    font-weight: 600;
    cursor: pointer;
  }
  .field-checkbox input {
    width: 20px;
    height: 10px;
    margin-right: 5px;
    box-shadow: unset;
    min-height: unset;
  }
  .field-checkbox .label1 {
    display: flex;
    align-items: center;
    margin: 0;
  }
  .anchor.ssolink {
    display: block;
    text-align: center;
    font-weight: 600;
  }
  .footer-link .span1 {
    font-size: 14px;
    text-align: center;
  }
  .listing .anchor {
    color: #697386;
    font-weight: 600;
    margin: 0 10px;
  }
  
  .animationRightLeft {
    animation: animationRightLeft 2s ease-in-out infinite;
  }
  .animationLeftRight {
    animation: animationLeftRight 2s ease-in-out infinite;
  }
  .tans3s {
    animation: animationLeftRight 3s ease-in-out infinite;
  }
  .tans4s {
    animation: animationLeftRight 4s ease-in-out infinite;
  }
  
  @keyframes animationLeftRight {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(1000px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  
  @keyframes animationRightLeft {
    0% {
      transform: translateX(0px);
    }
    50% {
      transform: translateX(-1000px);
    }
    100% {
      transform: translateX(0px);
    }
  }
  





















  :root {
    --cSize: 30px;
    --cInactive: #232739;
    --cActive: #003478;
    --particleSize: 0px;
    --cEasing: ease;
    --cTiming: 0.5s;
    --innerPaddingSize: 30px;
    --bg: #232739;
    --bgA: #003478;
  }
  

  
  .particles-checkbox {
    height: var(--cSize);
    width: var(--cSize);
    display: inline-flex;
    appearance: none;
    border: 3px solid lighten(var(--bg), 30%);
    border-radius: 50%;
    position: relative;
    cursor: pointer;
    transition: all var(--cTiming) var(--cEasing);
    margin: 2px;
  }
  .particles-checkbox:focus {
    outline: 0;
  }
  .particles-checkbox:checked {
    background-color: var(--cActive);
  }
  .particles-checkbox:checked::after {
    background-color: #ffffff;
    height: calc(var(--cSize) * 0.3);
    width: calc(var(--cSize) * 0.3);
  }
  .particles-checkbox:checked::before {
    animation: particleAnimate var(--cTiming) var(--cEasing) forwards;
  }
  .particles-checkbox:checked + span {
    border-color: var(--bgA);
    color: lighten(var(--bg), 60%);
  }
  .particles-checkbox::before,
  .particles-checkbox::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border-radius: inherit;
    height: 0px;
    width: 0px;
  }
  .particles-checkbox::after {
    transition: all var(--cTiming) var(--cEasing);
  }
  .particles-checkbox::before {
    --particleSize: 4px;
    height: var(--particleSize);
    width: var(--particleSize);
    z-index: 2;
  }
  
  @keyframes particleAnimate {
    0% {
      box-shadow: 0px 0px 0px transparent;
      transform: rotate(0turn);
      opacity: 1;
    }
    50%,
    100% {
      box-shadow: 0px 0px 4px #f94144, 4px 4px 4px #f9c74f, -4px -4px 4px #ffd166;
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: rotate(0.5turn);
    }
  }
  
  .particles-checkbox-container {
    display: inline-flex;
    /* margin: 20px; */
    align-items: center;
    border-radius: 8px;
    cursor: pointer;
    position: relative;
    transition: all var(--cTiming) var(--cEasing);
  }
  .particles-checkbox-container span {
    border: 3px solid transparent;
    border-radius: calc(var(--innerPaddingSize) * 0.5);
    padding: 10px;
    margin-left: 4px;
    /* padding: var(--innerPaddingSize); */
    padding-left: calc(var(--cSize) + var(--innerPaddingSize) + 10px);
    width: 180px;
    color: lighten(var(--bg), 30%);
    transition: all var(--cTiming) var(--cEasing);
  }
  .particles-checkbox-container .particles-checkbox {
    position: absolute;
    left: var(--innerPaddingSize);
  }
  
  .link {
    position: fixed;
    top: 0;
    left: 0;
    margin: auto;
    display: inline-flex;
    padding: 8px 16px;
    color: #ffffff;
    font-weight: 900;
    text-decoration: none;
    text-transform: uppercase;
    background-color: #003478;
  }
  