@keyframes popupSlideDown {
    0% {
      transform: translate(-50%, -50%) scale(0.5) translateY(-200%);
      opacity: 0;
    }
    100% {
      transform: translate(-50%, -50%) scale(1) translateY(0);
      opacity: 1;
    }
  }
  .popup-wrapper {
    position: fixed;
    width: 600px;
    height: auto;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    background-color: whitesmoke;
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* background: url("https://images.unsplash.com/photo-1564069114553-7215e1ff1890?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1932&q=80"); */
    background-size: cover;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: all 0.7s;
    z-index: 1001; /* Ensures it is above other elements */
    animation: popupSlideDown 0.5s ease-out;
  }

  
  
  .hidden {
    visibility: hidden;
    opacity: 0;
    top: 10%;
  }
  

 
  
  .submit-button {
    background-color: burlywood;
    padding: 10px;
    cursor: pointer;
    font-size: 20px;
    border: 1px solid;
  }
  
  .email-input {
    border: 1px solid;
    padding: 10px;
    margin: 10px 0;
    font-size: 20px;
    text-align: center;
  }
  
  .email-input::placeholder {
    color: burlywood;
  }
  
  .close-button {
    position: absolute;
    right: 20px;
    cursor: pointer;
    color: gray;
  }
  
  .close-button:hover {
    color: #333399;
  }
  









  /* App.js */

  .main-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    justify-content: center;
    /* background-image: url("https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=870&q=80"); */
    background-size: cover;
    text-align: center;
    transition: filter 0.7s;
  }
  

  
  ul {
    list-style-type: none;
    padding: 0;
  }
  
  .list-item {
    padding: 10px 20px;
    width: 200px;
    cursor: pointer;
    background-color: gold;
    border: none;
    border-radius: 5px;
    font-size: 20px;
    margin: 10px;
    text-align: center;
  }
  
  .list-item:hover {
    filter: brightness(0.7);
  }
  
  /* .blurred {
    filter: blur(5px) brightness(0.7);
  }
   */
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000; /* Below the popup but above the main content */
  }