.testimonial{ margin: 0 10px; }
.testimonial .testimonial-content{
    margin: 20px 0 50px 0;
    position: relative;
}
.testimonial .testimonial-content:after{
    content: "";
    width: 20px;
    height: 20px;
    border-top: 20px solid #dca842;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    margin: 0 auto;
    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
}
.testimonial .description{
    padding: 25px 35px;
    margin: 0;
    background: #dca842;
    border-radius: 15px;
    font-size: 18px;
    color: #fff;
    position: relative;
}
.testimonial .description:before,
.testimonial .description:after{
    font-family: "Font Awesome 5 Free"; font-weight: 900;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 8px;
    text-align: center;
    background: #dca842;
    position: absolute;
}
.testimonial .description:before{
    content: "\f10d";
    top: -18px;
    left: 25px;
}
.testimonial .description:after{
    content: "\f10e";
    bottom: -18px;
    right: 25px;
}
.testimonial .testimonial-profile{
    display: table;
    padding: 15px;
    border: 1px solid #dca842;
    border-radius: 20px;
    margin: 0 auto;
    position: relative;
}
.testimonial .pic{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid #dca842;
    float: left;
    overflow: hidden;
}
.testimonial .title{
    display: inline-block;
    margin: 0 0 0 30px;
    font-size: 19px;
    font-weight: bold;
    color: #dca842;
    position: relative;
    top: 22px;
}
.testimonial .title:before{
    content: "";
    width: 1px;
    height: 70px;
    background: #dca842;
    position: absolute;
    top: -8px;
    left: -15px;
}
.testimonial .post{
    display: block;
    font-size: 14px;
    font-weight: normal;
    color: #888;
    margin-top: 10px;
}
.owl-theme .owl-controls{
    margin-top: 30px;
}
.owl-theme .owl-controls .owl-page span{
    background: transparent;
    border: 1px solid #dca842;
    opacity: 1;
}
.owl-theme .owl-controls .owl-page.active span{
    background: #dca842;
    border: 1px solid #dca842;
}
@media only screen and (max-width: 479px){
    .testimonial .description{
        padding: 20px;
        font-size: 16px;
    }
    .testimonial .testimonial-profile{ padding: 10px; }
    .testimonial .title{ font-size: 16px; }
    .testimonial .post{ font-size: 13px; }
}


/* Custom styles for Swiper navigation buttons */
.swiper-button-next, .swiper-button-prev {
    /* width: 100px;
    height: 100px; */
    color: #dca842;
    /* background-color: rgba(0, 0, 0, 0.5); Optional: Add background color */
    /* border-radius: 50%; Optional: Make them circular */
}

/* .swiper-button-next::after, .swiper-button-prev::after {
    font-size: 25px; 
}

.swiper-button-next {
    right: 20px; 
}

.swiper-button-prev {
    left: 20px;
} */
