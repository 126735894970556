@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
* {
    padding: 0px;
    margin: 0px;
    box-sizing: border-box;
}

.tree-container {
    overflow-x: visible
    ;
    width: 100%;
}

.tree {
    width: 100%;
    height: auto;
    text-align: center;
    font-family: 'Poppins', sans-serif;
}

.tree ul {
    padding-top: 20px;
    position: relative;
    transition: .5s;
    padding-left: 0px;
}

.tree li {
    display: inline-table;
    text-align: center;
    list-style-type: none;
    position: relative;
    padding: 0px;
    transition: .5s;
}

.tree li::before, .tree li::after {
    content: '';
    position: absolute;
    top: 0;
    right: 50%;
    border-top: 1px solid #ccc;
    width: 51%;
    height: 10px;
}

.tree li::after {
    right: auto;
    left: 50%;
    border-left: 1px solid #ccc;
}

.tree li:only-child::after, .tree li:only-child::before {
    display: none;
}

.tree li:only-child {
    padding-top: 0;
}

.tree li:first-child::before, .tree li:last-child::after {
    border: 0 none;
}

.tree li:last-child::before {
    border-right: 1px solid #ccc;
    border-radius: 0 5px 0 0;
    -webkit-border-radius: 0 5px 0 0;
    -moz-border-radius: 0 5px 0 0;
}

.tree li:first-child::after {
    border-radius: 5px 0 0 0;
    -webkit-border-radius: 5px 0 0 0;
    -moz-border-radius: 5px 0 0 0;
}

.tree ul ul::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    border-left: 1px solid #ccc;
    width: 0;
    height: 20px;
}

.tree li a {
    border: 1px solid #ccc;
    padding: 10px;
    display: inline-block;
    border-radius: 5px;
    text-decoration: none;
    color: #666;
    transition: .5s;
}

.tree li a span {
    color: #666;
    padding: 8px;
    font-size: 12px;
    text-transform: uppercase;
    letter-spacing: 1px;
    font-weight: 500;
}

/*Hover-Section*/
.tree li a:hover, .tree li a:hover span, .tree li a:hover+ul li a {
    background: #c8e4f8;
    color: #000;
    border: 1px solid #94a0b4;
}

.tree li a:hover+ul li::after, .tree li a:hover+ul li::before, .tree li a:hover+ul::before, .tree li a:hover+ul ul::before {
    border-color: #94a0b4;
}

/* @media (max-width: 768px) {
    .tree li {
        display: block;
        text-align: left;
    }
    .tree li::before, .tree li::after {
        display: none;
    }
    .tree ul ul::before {
        display: none;
    }
    .tree li a {
        display: block;
        margin-bottom: 10px;
    }
} */
