.container-gallery {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(3, 1fr);
    margin: 0 auto;
    max-width: 90%;
    padding: 3rem 0;
    margin-top: -150px;
  }
  
  /* Grid images */
  .img-gallery {
    border-radius: 1rem;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  @media (min-width: 640px) {
    .container-gallery {
        grid-template-columns: repeat(1, 1fr);
        margin-top: -50px;
        grid-gap: 1rem;
    }
}
  /* @media (min-width: 768px) {
    .container-gallery {
        grid-template-columns: repeat(2, 1fr);
    }
} */

@media (min-width: 1024px) {
    .container-gallery {
        grid-template-columns: repeat(3, 1fr);
      
    }
}