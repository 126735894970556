/* Navbar.css */

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  padding: 10px 20px;

}

.navbar-wrapper {
  max-width: 1440px;
  width: 100%;
  padding: 0 14px;
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.navbar-logo {
  font-size: 32px;
  font-weight: 600;
  color: #0E46A3;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 15px;
}

.navbar-links li a {
  text-decoration: none;
  color: #333;
  font-weight: 500;

}

.navbar-links li a:hover {
  color: #b5651d;
}

.nav-adaptation.open {
  display: block;
  z-index: 0;
  animation: slideDown 0.3s ease-in-out;
}

/* Reset default list styles */
.nav-menu-dropdown,
.nav-menu-dropdown-list {
  list-style: none;
  padding: 0;
  margin: 0;
}


/* Main dropdown container */
.nav-menu-dropdown {
  position: relative;
  display: inline-block;
  border-radius: 10px;
}

/* Dropdown toggle */
.nav-menu-dropdown-toggle {
  cursor: pointer;
  padding: 10px 15px;
  color: #333;
  border-radius: 4px;
  font-size: 15px;
}

/* Dropdown list */
.nav-menu-dropdown-list {
  position: absolute;
  font-size: 14px;
  top: 100%;
  left: 0;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px);
}

/* Show dropdown on parent hover */
.nav-menu-dropdown:hover .nav-menu-dropdown-list {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

/* Individual dropdown items */
.nav-menu-item {
  display: block;
  padding: 10px 12px;
  color: #333;
  font-size: 15px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.nav-menu-item:hover {
  background-color: #ffffff;
  border-radius: 10px;
}

/* Submenu container */
.nav-menu-dropdown-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  transform: translateX(-10px);
  z-index: 1;
}

/* Show submenu on parent hover */
.nav-menu-item-drop:hover .nav-menu-dropdown-submenu {
  opacity: 1;
  visibility: visible;
  transform: translateX(0);
}

/* Individual submenu items */
.nav-menu-item-subdrop {
  display: block;
  min-width: 200px;
  max-width: 600px;
  padding: 10px 12px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.nav-menu-item-subdrop:hover {
  background-color: #f9f9f9;
}

/* Animation for individual item */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive styles */
@media (max-width: 768px) {
  .nav-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
    padding: 1rem 0;
    animation: slideDown 0.3s ease-in-out;
  }

  .nav-menu.open {
    display: flex;
  }

  .nav-item {
    margin: 0.5rem 0;
  }

  .menu-icon {
    display: block;
  }

  .nav-menu-dropdown {
    position: static;
    width: 100%;
    box-shadow: none;
    background-color: transparent;
    border-radius: 0;
    transition: none;
  }

  .nav-menu-dropdown-list {
    display: none;
    position: relative;
    opacity: 1;
    visibility: visible;
    transform: none;
    background-color: transparent;
    box-shadow: none;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  .nav-menu-item {
    padding: 10px 15px;
    font-size: 14px;
  }

  .nav-menu-dropdown:hover .nav-menu-dropdown-list {
    display: block;
    position: static;
  }

  .nav-menu-dropdown-submenu {
    position: static;
    left: 0;
    top: auto;
    width: 100%;
    transform: none;
  }

  .nav-menu-item-drop:hover .nav-menu-dropdown-submenu {
    display: block;
    position: static;
  }
}

/* About Dropdown */
.nav-about-dropdown {
  top: 100%;
  left: 0;
  width: 470px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  transform: translateY(-10px);
  z-index: 1;
  flex-wrap: nowrap;
  padding: 10px;
}

.nav-about-column {
  display: flex;
  flex-direction: column;
  padding: 10px 5px;
}

.nav-about-item {
  display: block;
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  font-size: 15px;
  transition: background-color 0.3s ease;
}

.nav-about-item:hover {
  background-color: #f9f9f9;
  border-radius: 10px;
}

.nav-about-image {
  border-left: 1px dashed darkblue;
}

/* Button Styles */
.button-71 {
  background-color: #0078d0;
  border: 0;
  border-radius: 56px;
  color: #fff;
  cursor: pointer;
  display: inline-block;
  font-family: system-ui, -apple-system, system-ui, "Segoe UI", Roboto, Ubuntu, "Helvetica Neue", sans-serif;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  font-weight: 100;
  font-style: italic;
  outline: 0;
  padding: 6px 17px;
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: all 0.3s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
}

.button-71:before {
  background-color: initial;
  background-image: linear-gradient(#fff 0, rgba(255, 255, 255, 0) 100%);
  border-radius: 125px;
  content: "";
  height: 30%;
  left: 4%;
  opacity: 0.5;
  position: absolute;
  top: 0;
  transition: all 0.3s;
  width: 92%;
}

.button-71:hover {
  box-shadow: rgba(255, 255, 255, 0.2) 0 3px 15px inset, rgba(0, 0, 0, 0.1) 0 3px 5px, rgba(0, 0, 0, 0.1) 0 10px 13px;
  transform: scale(1.05);
}
/* MultiLevelDropdown.css */
.dropdown-menu {
  display: none;
  
}
.dropdown:hover {
  background-color: #ffffff;
  border-radius: 10px;
}
.dropdown:hover>.dropdown-menu {
  display: block;
font-size: 15px;
animation: fadeIn 0.5s ease forwards;

}

.dropdown-submenu {
  position: relative;
}

.dropdown-submenu>.dropdown-menu {
  top: 0;
  left: 100%;
  /* margin-top: -1px; */
  display: none;
  font-size: 15px;
}

.dropdown-submenu:hover>.dropdown-menu {
  display: block;
}

.dropdown-menu ul {
  animation: fadeIn 0.5s ease forwards;
}
@media (max-width: 768px) {
  .dropdown-submenu .dropdown-menu {
    position: static;
    display: none;
    width: auto;
    box-shadow: none;
  }

  /* .dropdown-submenu:hover .dropdown-menu {
    display: block;
  } */
}
