.navbar {
  background-color: #333;
}

.menu {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.menu > li {
  position: relative;
}

.menu > li > a {
  display: block;
  padding: 15px;
  color: #fff;
  text-decoration: none;
}

.menu > li:hover > .submenu {
  display: block;
}

.submenu {
  display: none;
  position: absolute;
  background-color: #444;
  width: 200px;
  top: 100%;
  left: 0;
  z-index: 1;
}

.submenu li {
  border-bottom: 1px solid #666;
}

.submenu li:last-child {
  border-bottom: none;
}

.submenu a {
  padding: 10px;
  display: block;
  color: #fff;
  text-decoration: none;
}
