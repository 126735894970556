
  

  
  .responsive-table li {
    border-radius: 3px;
    padding: 20px 25px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
  }
  
  .responsive-table .table-header {
  
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  }

  .responsive-table .table-header-color{
  background-color: #95A5A6;
  }
  
  .responsive-table .table-row {
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.1);
  }
  
  .responsive-table .table-row  .col-1 {
    flex-basis: 10%;
  }
  
  .responsive-table .table-row .col-2 {
    flex-basis: 40%;
  }
  
  .responsive-tabl .table-row .col-3 {
    flex-basis: 25%;
  }
  
  .responsive-table .table-row .col-4 {
    flex-basis: 25%;
  }
  
  @media all and (max-width: 767px) {
    .responsive-table .table-header {
      display: none;
    }
  
    .responsive-table li {
      display: block;
    }
  
    .responsive-table .col {
      flex-basis: 100%;
      display: flex;
      padding: 10px 0;
    }
  
    .responsive-table .col:before {
      color: #6C7A89;
      padding-right: 10px;
      content: attr(data-label);
      flex-basis: 50%;
      text-align: right;
    }
  }
  