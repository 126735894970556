/* Colors */
:root {
    --black: #000;
    --grey: #595959;
    --grey-dark: #2b2b2b;
    --grey-light: #eee;
    --green: #86c023;
    --blue: #017ac7;
  
    --duration: 400ms;
    --easing: ease;
  }
  
  /* Pagination */
  .pagination-wrapper {
    text-align: center;
  }
  
  .pagination {
    display: inline-block;
    height: 70px;
    margin-top: 70px;
    padding: 0 25px;
    border-radius: 35px;
    background-color: var(--grey-light);
  }
  
  @media only screen and (min-width: 120px) and (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 1024px),
  only screen and (min-width: 120px) and (max-width: 1024px),
  only screen and (min-width: 1025px) {
    .pagination {
      height: 50px;
      margin-top: 50px;
      padding: 0 10px;
      border-radius: 25px;
    }
  }
  
  .page-numbers {
    display: block;
    padding: 0 25px;
    float: left;
    transition: var(--duration) var(--easing);
    color: var(--grey);
    font-size: 20px;
    letter-spacing: 0.1em;
    line-height: 40px;
  }
  
  .page-numbers:hover,
  .page-numbers.current {
    background-color: #454cad;
    color: #fff;
  }
  
  .page-numbers.prev:hover,
  .page-numbers.next:hover {
    background-color: transparent;
    color: #454cad;
  }
  
  @media only screen and (min-width: 120px) and (max-width: 767px),
  only screen and (min-width: 768px) and (max-width: 1024px),
  only screen and (min-width: 120px) and (max-width: 1024px),
  only screen and (min-width: 1025px) {
    .page-numbers {
      padding: 0 15px;
      font-size: 16px;
      line-height: 50px;
    }
  }
  
  @media only screen and (min-width: 120px) and (max-width: 1024px) {
    .page-numbers {
      padding: 0 14px;
      display: none;
    }
  
    .page-numbers:nth-of-type(2) {
      position: relative;
      padding-right: 50px;
    }
  
    .page-numbers:nth-of-type(2)::after {
      content: "...";
      position: absolute;
      font-size: 25px;
      top: 0;
      left: 45px;
    }
  
    .page-numbers:nth-child(-n + 3),
    .page-numbers:nth-last-child(-n + 3) {
      display: block;
    }
  
    .page-numbers:nth-last-child(-n + 4) {
      padding-right: 14px;
    }
  
    .page-numbers:nth-last-child(-n + 4)::after {
      content: none;
    }
  }
    