/* .admission-procedure {
    background: linear-gradient(to bottom right, #fff, #f0f4ff);
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px 40px;
    max-width: 800px;
    margin: 40px auto;
  }
  
  .admission-procedure h2 {
    text-align: center;
    font-size: 2.5em;
    color: #4a90e2;
    margin-bottom: 10px;
  }
  
  .admission-procedure h3 {
    text-align: center;
    font-size: 1.8em;
    color: #333;
    margin-bottom: 20px;
  } */
  
  .steps {
    list-style-type: none;
    padding: 0;
  }
  
  .step {
    display: flex;
    align-items: start;
    margin-bottom: 20px;
    position: relative;
    padding-left: 40px;
  }
  
  .step::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    width: 2px;
    height: 100%;
    background: #ddd;
  }
  
  .step-number {
    font-size: 1.5em;
    color: #fff;
    background: #1679AB;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .step-text {
    font-size: 1.1em;
    color: #333;
  }
  
  .step:nth-child(even) .step-number {
    background: #50b3a2;
  }
  
  .step:last-child::before {
    height: 20px;
  }
  