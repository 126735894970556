/*
* expandable menu started
*/
@media (max-width: 1280px) {
  .header__search-form {
    display: none;
  }
  .cta-two__single {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    padding: 70px;
  }
  .cta-two__icon {
    margin-right: 0;
    margin-bottom: 30px;
  }
  .cta-two__title {
    font-size: 40px;
  }
}

@media (max-width: 1199px) {
  .header-navigation {
    margin-bottom: 0;
  }
  .logo-box {
    width: 100%;
    float: none;
    margin: 0 !important;
  }
  .navigation-box {
    margin: 0;
  }
  .navbar-expand-lg .menu-toggler {
    display: block;
    margin-right: 0;
    border-color: #fff;
    background-color: transparent !important;
    margin: 23px 0;
    color: #fff;
    border: none;
    font-size: 28px;
    float: right;
    outline: none;
    cursor: pointer;
  }
  .menu-toggler:hover {
    border-color: #f16101;
  }
  .menu-toggler .icon-bar {
    background: #fff;
  }
  .menu-toggler:hover .icon-bar {
    background: #f16101;
  }
  .navbar-expand-lg .navbar-collapse {
    padding-bottom: 0;
    overflow: auto !important;
    width: 100%;
  }
  .navbar-expand-lg .navbar-collapse {
    display: none !important;
    background: transparent;
    padding: 0px 0px !important;
    padding-bottom: 0px !important;
    margin: 0;
    background: #000;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
    height: 0;
  }
  .navbar-expand-lg .navbar-collapse.showen {
    display: block !important;
    height: auto;
    max-height: 70vh;
  }
  .navbar-collapse.show {
    overflow-y: auto;
  }
  .header-navigation .main-navigation {
    float: none !important;
    text-align: left !important;
  }
  .header-navigation .nav {
    width: 100%;
    text-align: left;
  }
  .header-navigation .nav > li {
    display: block;
    padding: 0 !important;
    width: 100%;
    float: none;
  }
  .header-navigation .nav > li + li {
    margin-left: 0 !important;
  }
  .header-navigation .nav > li > a {
    padding: 8px 0;
  }
  .header-navigation .right-box.nav {
    width: 100%;
    margin: 0;
  }
  .header-navigation .right-box.nav > li > a {
    border: none !important;
    padding: 8px 0;
    color: #fff;
  }
  .header-navigation .nav > li > a:after {
    display: none;
  }
  .header-navigation .nav.navigation-box + .nav {
    border-top: 1px dashed #012237;
  }
  .header-navigation .navigation-box > li > .sub-menu,
  .header-navigation .navigation-box > li > .sub-menu > li > .sub-menu {
    position: relative !important;
    width: 100% !important;
    opacity: 1 !important;
    visibility: visible !important;
    left: auto !important;
    right: auto !important;
    top: auto !important;
    -webkit-transition: none !important;
    transition: none !important;
    display: none;
    float: none !important;
    margin: 0;
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
  }
  .header-navigation .navigation-box > li > .sub-menu > li > a::after {
    display: none;
  }
  .header-navigation ul.navigation-box > li + li {
    padding: 0 !important;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    display: block;
    float: none;
    margin: 0 !important;
  }
  .header-navigation .nav > li.show-mobile {
    display: none;
  }
  .header-navigation ul.navigation-box > li > a,
  .header-navigation .right-box.nav > li > a {
    padding: 0;
    display: block;
    color: #fff;
    padding: 16px 0 16px 20px !important;
    -webkit-transition: background 0.4s ease 0s;
    transition: background 0.4s ease 0s;
  }
  .header-navigation ul.navigation-box li a .sub-nav-toggler {
    background-color: transparent;
    background-image: none;
    border: 1px solid #fff;
    border-radius: 4px;
    padding: 9px 9px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 15px;
    /*margin-top: 8px;*/
    margin-right: 0px;
    display: block;
    cursor: pointer;
    outline: none;
  }
  .header-navigation .navigation-box .sub-nav-toggler .icon-bar {
    background-color: #fff;
    border-radius: 1px;
    display: block;
    height: 1px;
    width: 20px;
  }
  .header-navigation .navigation-box .sub-nav-toggler .icon-bar + .icon-bar {
    margin-top: 4px;
  }
  .header-navigation .container .right-side-box {
    top: 20px;
    right: 15px;
    -webkit-transform: translateY(0%);
            transform: translateY(0%);
  }
  .header-navigation .container {
    display: block;
    padding-right: 0px;
    padding-left: 0px;
    position: relative;
  }
  .header-navigation .container .logo-box {
    position: relative;
    top: 0;
    left: 0;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    float: none;
    padding-right: 145px;
  }
  .header-navigation .container .logo-box .navbar-brand {
    float: left;
    padding: 20px 0;
  }
  .header-navigation .container .logo-box .navbar-toggler {
    float: left;
  }
  .header-navigation ul.navigation-box {
    display: block;
    margin: 0 !important;
    width: 100%;
  }
  .header-navigation ul.navigation-box > li {
    padding: 0;
    display: block;
  }
  .header-navigation ul.navigation-box > li > a {
    display: block;
    padding: 13px 30px;
  }
  .header-navigation ul.navigation-box > li > a:after {
    display: none;
  }
  .header-navigation ul.navigation-box > li + li {
    margin: 0;
  }
  .header-navigation .main-navigation {
    float: none;
    width: 100%;
    display: none;
    text-align: left;
    background: #18212E;
    max-height: 70vh;
    overflow-y: scroll;
  }
  .header-navigation .container .menu-toggler {
    display: block;
  }
  .header-navigation.stricky-fixed ul.navigation-box > li {
    padding: 0;
  }
  .header-navigation .container .logo-box .navbar-brand {
    background-color: transparent;
  }
  .header-navigation .container .menu-toggler {
    float: left;
    margin: 28px 0;
    color: #81868a;
    margin-left: 0px;
    position: relative;
    top: 5px;
  }
  .header-navigation .container .logo-box {
    padding-right: 15px !important;
  }
  .header-navigation {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }
  .header-navigation .container .logo-box {
    left: 0 !important;
  }
  .header-navigation .container .logo-box {
    padding-left: 15px;
  }
  .header-navigation .main-navigation {
    padding-right: 0;
  }
  .header__social {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .site-header__home-three .header-navigation .container .menu-toggler {
    position: relative;
    top: 10px;
    color: #ffffff;
    margin-left: 25px;
  }
  .site-header__header-two .header-navigation .container .menu-toggler {
    margin-left: 20px;
  }
  .site-header__header-two .header-navigation .container .logo-box {
    left: 15px;
  }
  .site-header__header-two .header-navigation .container .right-side-box {
    right: 0px;
  }
  .site-header__header-two .header-navigation .main-navigation,
  .site-header__header-two .header-navigation ul.navigation-box > li {
    padding: 0;
  }
  .site-header__inner-page .header-navigation .main-navigation {
    padding-right: 0;
  }
}

@media (max-width: 425px) {
  .header-navigation .container .right-side-box {
    display: none !important;
  }
  .header-navigation .container .menu-toggler {
    float: right !important;
  }
}

@media (max-width: 1199px) {
  .video-one__title,
  .video-two__title {
    font-size: 40px;
    margin-bottom: 40px;
  }
  .video-one__title {
    margin-bottom: 0;
  }
  .countdown-one .become-teacher__form {
    padding-left: 0;
  }
  .blog-two__title {
    font-size: 20px;
  }
  .blog-two__inner {
    padding: 30px;
  }
  .mailchimp-one__title {
    font-size: 30px;
  }
  .footer-widget {
    padding: 0;
    position: static;
    margin-top: 30px;
  }
  .footer-widget__about {
    padding: 0;
  }
  .footer-widget__about::before {
    display: none;
  }
  .site-footer__upper {
    padding-top: 50px;
    padding-bottom: 80px;
  }
  .header__search-form {
    display: block;
  }
  .meeting-one img,
  .about-one__img img {
    max-width: 100%;
  }
  .pricing-one__single {
    padding-left: 60px;
    padding-right: 20px;
  }
  .meeting-one__content {
    padding-left: 30px;
  }
  .blog-one__title {
    font-size: 20px;
  }
  .blog-one__content {
    padding-left: 30px;
    padding-right: 30px;
  }
  .slider-three__title {
    font-size: 65px;
    line-height: 65px;
  }
  .course-category-three__single img {
    max-width: 100%;
  }
  .cta-six__content,
  .cta-six__right,
  .cta-six__left {
    float: none;
    width: 100%;
    max-width: 500px;
    margin-left: auto;
    margin-right: auto;
    padding: 70px 0;
  }
  .cta-six__content img,
  .cta-six__right img,
  .cta-six__left img {
    width: 100%;
  }
  .cta-six__content {
    padding: 0;
  }
  .course-details__review-box {
    margin-top: 40px;
  }
  .about-two__image {
    margin-top: 50px;
  }
}

@media (max-width: 991px) {
  .banner-one__title {
    font-size: 100px;
    line-height: 1em;
  }
  .video-two__scratch {
    display: none;
  }
  .video-two__popup {
    margin-top: 40px;
  }
  .countdown-one .become-teacher__form {
    margin-top: 40px;
  }
  .cta-three__image {
    max-width: 100%;
  }
  .cta-three__content {
    padding: 50px 0;
  }
  .mailchimp-one__content {
    margin-bottom: 40px;
  }
  .header__search-form {
    display: none;
  }
  .site-header__header-two .header-navigation .container .right-side-box {
    top: 29px;
  }
  .banner-two__title {
    font-size: 80px;
    line-height: 1em;
  }
  [class*="cta-five__members-"],
  .about-one__text br {
    display: none;
  }
  .pricing-one__single {
    text-align: center;
  }
  .meeting-one__content {
    padding-left: 0;
    padding-top: 40px;
  }
  .video-one__img img,
  .course-category-three__single img {
    width: 100%;
  }
  .team-tab .tab-content {
    padding-top: 80px;
  }
  .team-tab .tab-content img {
    max-width: 100%;
    margin-top: 40px;
  }
  .cta-six .block-title__title {
    font-size: 40px;
  }
  .faq-one [class*="col-lg-"],
  .faq-one [class*="col-lg-"]:nth-child(2n) {
    padding: 0 !important;
    border: none;
  }
  .faq-one .col-lg-6 + .col-lg-6 {
    margin-top: 40px;
  }
  .faq-one__title {
    margin-bottom: 30px;
  }
  .inner-banner__title {
    font-size: 70px;
  }
  .course-details__price {
    margin-top: 40px;
  }
  .team-details .team-one__image img {
    width: 100%;
  }
  .team-details .team-one__single {
    margin-top: 50px;
  }
  .become-teacher__form {
    margin-top: 50px;
    padding-left: 0;
  }
  .contact-info-one {
    padding-top: 80px;
  }
  .contact-info-one__single {
    margin-top: 30px;
  }
}

@media (max-width: 767px) {
  .banner-one .banner-one__person {
    display: none !important;
  }
  .cta-five__title,
  .cta-one__home-one .cta-one__title {
    font-size: 40px;
    line-height: 45px;
    margin-bottom: 40px;
  }
  .cta-five__title {
    margin-bottom: 10px;
  }
  .cta-six__title {
    padding-left: 30px;
  }
  .site-footer__bottom .container {
    padding-top: 40px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
  .site-footer__social::before {
    display: none;
  }
  .site-footer__scroll-top {
    left: 120%;
  }
  .block-title__title {
    font-size: 40px;
    line-height: 46px;
  }
  .blog-details__author-image,
  .blog-details__author-content {
    display: block;
  }
  .blog-details__author-content {
    padding-left: 0;
    margin-top: 30px;
  }
  .cta-two__text br {
    display: none;
  }
  .cta-one .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
  }
  .cta-one__title {
    margin-bottom: 30px;
  }
}

@media (max-width: 575px) {
  .banner-one__cta {
    display: none;
  }
  .banner-wrapper .banner-carousel-btn [class*="banner-carousel-btn__"] {
    position: static;
    margin: 0 20px;
  }
  .banner-wrapper .banner-carousel-btn {
    position: absolute;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    bottom: 30px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 91;
  }
  .course-category-two .inner-container {
    margin-top: 0;
  }
  .about-one__about-page .about-one__img::before {
    display: none;
  }
  .team-tab__top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .team-tab__social {
    margin-top: 20px;
  }
  .about-two__image img {
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .site-header__header-two .header-navigation .container .right-side-box {
    right: 15px;
  }
  .header-navigation .container .menu-toggler {
    float: right !important;
  }
  .site-header__home-three .header-navigation .container .right-side-box {
    top: 30px;
  }
  .about-one__img::before {
    display: none;
  }
  .site-footer__scroll-top {
    left: 90%;
    width: 40px;
    height: 40px;
    top: 10%;
  }
}

@media (max-width: 375px) {
  .header__social {
    display: none;
  }
  .header-navigation .container .logo-box:after {
    display: none;
  }
  .header-navigation .container .logo-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .topbar-one .container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .topbar-one__right,
  .topbar-one__left {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .banner-one__title {
    font-size: 50px;
  }
  .banner-one__scratch {
    display: none !important;
  }
  .banner-one__tag-line {
    font-size: 30px;
  }
  .countdown-one__list {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .countdown-one__list li {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
    max-width: 50%;
    margin-bottom: 30px;
  }
  .become-teacher__form-content {
    padding-left: 20px;
    padding-right: 20px;
  }
  .cta-three__single {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 50%;
            flex: 1 1 50%;
    margin-top: 30px;
    max-width: 50%;
  }
  .cta-three__single-wrap {
    margin-top: -20px;
  }
  .mailchimp-one__content {
    text-align: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .mailchimp-one__form .thm-btn {
    position: relative;
    margin-top: 15px;
  }
  .mailchimp-one__icon i {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .slider-three__title,
  .banner-two__title {
    font-size: 40px;
    line-height: 1.2em;
  }
  .pricing-one__single {
    text-align: left;
  }
  .meeting-one__box-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .meeting-one__box + .meeting-one__box {
    margin-top: 50px;
  }
  .team-tab__content {
    padding-right: 0;
  }
  .team-tab__tab-navs {
    bottom: 3%;
  }
  .cta-six__title {
    font-size: 25px;
  }
  .video-three__title {
    font-size: 30px;
    line-height: 1.2em;
  }
  .video-three__btn-box {
    margin-top: 100px;
  }
  [class*="cta-five__bubble-"] {
    display: none;
  }
  .cta-five__title {
    font-size: 35px;
  }
  .course-details__top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .course-details__tab-navs li {
    width: 100%;
    text-align: center;
  }
  .team-details__certificate-list,
  .course-details__list-item,
  .course-details__comment-top,
  .course-details__comment-meta,
  .course-details__meta-link,
  .course-details__progress-item {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
  }
  .team-details__certificate-list li + li {
    margin-left: 0;
    margin-top: 20px;
  }
  .course-details__comment-img {
    margin-bottom: 20px;
  }
  .course-details__progress-bar {
    margin-left: 0;
    margin-right: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .course-details__tab-content {
    padding-left: 15px;
    padding-right: 15px;
  }
  .course-details__comment {
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
  }
  .course-details__comment-form-btn {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 16px;
  }
  .course-details__meta-link span {
    margin-left: 0;
  }
  .course-details__list-img {
    margin-bottom: 15px;
  }
  e,
  .inner-banner__title {
    font-size: 40px;
    margin: 0;
  }
  .inner-banner .container {
    padding: 100px 0;
    text-align: center;
  }
  .become-teacher__form-top {
    padding-left: 30px;
    padding-right: 30px;
  }
  .cta-one__title {
    font-size: 35px;
  }
  .banner-two .container {
    padding-top: 100px;
    padding-bottom: 180px;
  }
  .blog-details .share-block {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    text-align: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .comment-one__top-left,
  .comment-one__top-right,
  .comment-one__image,
  .comment-one__content {
    display: block;
  }
  .comment-one__top-right,
  .comment-one__content {
    padding-left: 0;
    margin-top: 20px;
  }
}
