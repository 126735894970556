body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

.slider {
    position: relative;
    width: 100%;
    /* height: 600px; */
    overflow: hidden;
}

.slides {
    display: flex;
    width: 100%;
    height: 100%;
    z-index: 0;
    transition: transform 0.5s ease-in-out;
}

.slide {
    min-width: 100%;
    height: 100%;
    background-position: 50% 35%;
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    flex: 1;
}

.navigation {
    position: absolute;
    width: 100%;
    top: 50%;
    display: flex;
    justify-content: space-between;
    transform: translateY(-50%);
}

.nav-btn {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.7);
    padding: 10px;
    border-radius: 50%;
}

.nav-btn:hover {
    background-color: rgba(255, 255, 255, 1);
}
